import React, { useState, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { translateText, translateImageCaption } from '../config/translation'

const useStyles = makeStyles(theme => ({
  headerImageWrapper: {
    maxWidth: 1400,
    margin: '0 auto',
  },
  textWrapper: {
    padding: 32,
    textAlign: 'justify',
  },
  inlinePhotoLeft: {
    [theme.breakpoints.up('sm')]: {
      width: 400,
      float: 'left',
      marginRight: 32,
    },
  },
  inlinePhotoRight: {
    [theme.breakpoints.up('sm')]: {
      width: 400,
      float: 'right',
      marginLeft: 32,
    },
  },
  photosWrapper: {
    marginTop: 64,
  },
  photosTitle: {
    textAlign: 'center',
  },
  imgCaption: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 300,
  },
}))

const OurStory = ({ path, lang }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
  query {
    kellioStory: file(relativePath: { eq: "images/kellio-story.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kellioTango: file(relativePath: { eq: "images/kellio-tango.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kellioMaine: file(relativePath: { eq: "images/kellio-maine1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kellioWedding: file(relativePath: { eq: "images/kellio-wedding2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kellioWeddingFamily: file(relativePath: { eq: "images/kellio-wedding1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photosQuery: allPhotosJson {
      edges {
        node {
          title
          fileName {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`)

  const photos = data.photosQuery.edges.map((e, i) => {
    return {
      src: e.node.fileName.childImageSharp.fluid.src,
      srcSet: e.node.fileName.childImageSharp.fluid.srcSet,
      sizes: e.node.fileName.childImageSharp.fluid.sizes,
      width: e.node.fileName.childImageSharp.fluid.presentationWidth,
      height: e.node.fileName.childImageSharp.fluid.presentationHeight,
      alt: e.node.title,
      key: `id_${i}`,
    }
  })

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Layout pageName='Our Story' activeRoute={ path } lang={ lang }>
      <SEO title="Our Story" />
      <Box className={ classes.headerImageWrapper }>
        <Img fluid={data.kellioStory.childImageSharp.fluid} />
      </Box>
      <Container maxWidth="md" className={ classes.textWrapper }>
        <p>{ translateText("Kelly and Helio met on December 10th, 2016, in Philadelphia. Helio loves Argentinian Tango, and brought Kelly to a milonga for their first date (fortunately for Kelly, a beginner class was held before she got on the dance floor!).", lang) }</p>
        <Box className={ classes.inlinePhotoLeft }>
          <Img fluid={data.kellioTango.childImageSharp.fluid} />
          <p className={ classes.imgCaption }>{ translateImageCaption("At a milonga in Philadelphia", lang) }</p>
        </Box>
        <p>{ translateText("A week after their first date, Helio moved to Brattleboro, Vermont, to start a new job as a Software Engineer. Even though Kelly was still living in Philadelphia, their connection was strong enough to bridge the 300 miles between them, with Helio driving to and from Vermont on the weekends, and Kelly flying from Philadelphia to Boston. Over the years, they made many fun memories together and tried to make the most out of every minute they had together, exploring Philadelphia, Vermont, and just about everywhere in between!", lang) }</p>
        <Box className={ classes.inlinePhotoRight }>
          <Img fluid={data.kellioMaine.childImageSharp.fluid} />
          <p className={ classes.imgCaption }>{ translateImageCaption("Minutes before Helio proposed on Sunset Rock", lang) }</p>
        </Box>
        <p>{ translateText("On August 10th, 2018, while on vacation with Kelly’s family on Southport Island, Maine, Helio proposed to Kelly on “Sunset Rock,” with Kelly’s immediate family in attendance.", lang) }</p>
        <p>{ translateText("In October of 2018, Helio moved to Philadelphia to live with Kelly, where they both now reside.", lang) }</p>
        <p>{ translateText("On October 27th, 2018, Kelly and Helio were legally wed in a private ceremony on Southport Island, Maine. The ceremony was attended by Helio’s parents, Hae Song Ha and Ock Cheon Lee, of Sao Paulo, Brazil; Kelly’s parents, Scott and Kimberly Winter of Canton, Massachusetts; and Kelly’s sister, Lauren Winter, of Canton, Massachusetts.", lang) }</p>
        <Box className={ classes.inlinePhotoLeft }>
          <Img fluid={data.kellioWeddingFamily.childImageSharp.fluid} />
          <p className={ classes.imgCaption }>{ translateImageCaption("The wedding party at the ceremony at All Saints-by-the-Sea on Southport Island, ME", lang) }</p>
        </Box>
        <p>{ translateText("We are so excited to be able to host our family and friends in Philadelphia, a city that is special to us and our story!", lang) }</p>
        <p>{ translateText("Although these are the highlights of our story, a picture is worth a thousand words. Another one of Helio’s hobbies is photography, and over the years he has captured many of the moments that he and Kelly have shared together, both big and small. We invite you to scroll through the photos below to see some of the highlights of Kelly and Helio’s relationship.", lang) }</p>
      </Container>
      <Container maxWidth="md" className={ classes.photosWrapper }>
        <h1 className={ classes.photosTitle }>Photos</h1> 
        <Gallery photos={photos} onClick={openLightbox} />
      </Container>

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      
    </Layout>
  )
}

export default OurStory
